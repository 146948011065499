<template>
  <div>
    <input ref="fileInput" style="display: none" type="file" @change="onFileSelected">
    <div v-if="image && !withoutPreview" class="image-preview">
      <img :src="image.path ? image.path : previewUrl" class="preview">
    </div>
    <div v-if="!image && previewUrl && !withoutPreview" class="image-preview">
      <img :src="previewUrl" class="preview">
    </div>
    <button class="btn btn-outline mt-3" size="sm" @click="$refs.fileInput.click()">
      <span class="text-dark">{{ file ? textChange : textUpload }}</span>
    </button>
  </div>
</template>

<script>

export default {
  props: {
    value: {
      type: Object,
      required: false
    },
    withoutPreview: {
      type: Boolean,
      default: false
    },
    withoutUpload: {
      type: Boolean,
      default: false
    },
    textChange: {
      type: String,
      default: 'Замените изображение'
    },
    textUpload: {
      type: String,
      default: 'Загрузите изображение'
    }
  },
  computed: {
    image: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value)
      }
    }
  },
  data() {
    return {
      file: null,
      previewUrl: null,
      show: !this.image,
    }
  },
  methods: {
    onFileSelected(event) {
      this.file = event.target.files[0];
      this.previewUrl = URL.createObjectURL(this.file);

      if (this.withoutUpload) {
        this.image.file = this.file;
      } else {
        this.saveImage();
      }
    },
    async saveImage() {
      try {
        let formData = new FormData();
        formData.append('image', this.file);
        await this.$store.dispatch('CREATE_IMAGE', formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        });
        this.$notify({
          type: "success",
          message: 'Изображение успешно загружено'
        });
        this.image = this.$store.getters["IMAGE"];
      } catch (error) {
        this.$notify({
          type: "danger",
          message: "Уупс, что-то пошло не так!",
        });
      }
    }
  }
}
</script>
<style scoped>
img.preview {
  width: 150px;
  background-color: white;
  border: 1px solid #DDD;
  padding: 5px;
}
</style>